import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import JobMatches from './job_matches/JobMatches';
import { Job } from '../../models/job';
import { ChevronRightIcon, ListBulletIcon, Squares2X2Icon } from '@heroicons/react/20/solid';
import Loader from '../Loader';
import { GetJobPosting } from '../../services/job-postings';
import { useAxios } from '../../contexts/axios';
import JobCardDeck from './job_matches/JobCardDeck';
import JobListDropdown from './JobListDropdown';
import { DeleteJob } from '../../utils/job';
import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline';
import { getReviewSections, ReviewSection } from './NewJobReview';

const banner = [
  {
    title: 'Job Details',
    description: 'Review Details',
    value: (_job: Job) => -1,
    filter: '',
  },
  {
    title: 'New Matches',
    description: 'Check Them Out',
    value: (job: Job) => job.stats.new_matches,
    filter: 'recent',
  },
  {
    title: 'Interviews',
    description: 'Next Interview Date Time',
    value: (job: Job) => job.stats.approved_matches,
    filter: 'approved',
  },
  {
    title: 'Expiring Matches',
    description: 'Action These Soon',
    value: (job: Job) => job.stats.expiring_matches,
    filter: 'expiring',
  },
];

export default function JobPage() {
  const [loading, setLoading] = useState(true);
  const [isListView, setIsListView] = useState(true);
  const [matchesFilter, setMatchesFilter] = useState('');
  const [job, setJob] = useState<Job>();
  const { id } = useParams();
  const { axios } = useAxios();
  const navigate = useNavigate();

  useEffect(() => {
    if (id === undefined) return;

    GetJobPosting(axios, id).then(
      (res) => {
        setJob(res.data);
        setLoading(false);
      },
      (err) => {
        console.error(err);
        setLoading(false);
      }
    );
  }, [id, isListView]);

  function DeleteJobCallback(id: string): void {
    navigate('/jobs');
  }

  function editSection(idx: number): void {
    navigate(`/jobs/${id}/edit`, { state: { initJob: job, index: idx } });
  }

  return loading ? (
    <Loader className="absolute left-1/2 top-1/2 flex" />
  ) : (
    <div>
      <div className="flex flex-row justify-between gap-x-4">
        <div className="text-xl font-semibold">
          <Link className="hover:border-b-2 hover:border-black" to="/jobs">
            Jobs
          </Link>{' '}
          <ChevronRightIcon className="inline h-8 fill-primary" />
          {job === undefined ? 'No Job Found' : job?.title}
          {matchesFilter != '' ? (
            <>
              <ChevronRightIcon className="inline h-8 fill-primary" />{' '}
              {
                banner.find((item) => {
                  return item.filter == matchesFilter;
                })?.title
              }
            </>
          ) : null}
        </div>

        <div className="flex gap-3">
          <div>
            <button
              className={clsx('rounded-l p-2', isListView ? 'bg-primary' : 'bg-white')}
              onClick={() => setIsListView(true)}
            >
              <ListBulletIcon className={clsx('h-5 w-5', isListView ? 'fill-white' : 'fill-primary')} />
            </button>
            <button
              className={clsx('rounded-r p-2', isListView ? 'bg-white' : 'bg-primary')}
              onClick={() => setIsListView(false)}
            >
              <Squares2X2Icon className={clsx('h-5 w-5', isListView ? 'fill-primary' : 'fill-white')} />
            </button>
          </div>
          {job && <JobListDropdown job={job} deleteJobCallback={DeleteJobCallback} />}
        </div>
      </div>
      <div className="mt-7 flex flex-col overflow-clip rounded-xl bg-white">
        <div className="rounded-top-xl flex flex-col lg:flex-row">
          {banner.map((item, index) => (
            <button
              key={item.filter}
              className={clsx(
                'flex w-full items-center justify-center gap-5 border-b p-4',
                matchesFilter == item.filter ? 'bg-primary text-white' : 'bg-white text-primary',
                index != 0 && index != banner.length - 1 ? 'lg:border-x' : ''
              )}
              onClick={() => setMatchesFilter(item.filter)}
            >
              <div className="min-w-[4rem] rounded-full bg-lightblue p-4 text-center text-xl font-semibold text-primary">
                {item.value(job!) === -1 ? <ClipboardDocumentListIcon /> : item.value(job!)}
              </div>
              <div className="flex flex-col">
                <span className="text-lg font-semibold">{item.title}</span>
                <span className={clsx('text-sm', matchesFilter == item.filter ? 'text-white' : 'text-black')}>
                  {item.description}
                </span>
              </div>
            </button>
          ))}
        </div>

        <div className="p-8">
          {(() => {
            if (job === undefined) {
              return <span className="flex justify-center">No Job Found</span>;
            }

            if (matchesFilter === '') {
              return (
                <>
                  <h1 className="pb-8 font-medium">Job Details</h1>

                  <div className="flex flex-col gap-5 rounded-xl border p-5 px-10 py-5">
                    {getReviewSections(job).map((section, idx) => (
                      <ReviewSection key={section.title} onClick={() => editSection(idx)} {...section} />
                    ))}
                  </div>
                </>
              );
            }

            if (isListView) {
              return <JobMatches job={job} filter={matchesFilter} />;
            } else {
              return <JobCardDeck job={job} filter={matchesFilter} />;
            }
          })()}
        </div>
      </div>
    </div>
  );
}
