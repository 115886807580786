import React, { createContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import NewJobDetails from './NewJobDetails';
import NewJobGeneral from './NewJobGeneral';
import NewJobBenefits from './NewJobBenefits';
import { Job } from '../../models/job';
import { useAxios } from '../../contexts/axios';
import { Mixpanel, MixpanelEventType } from '../../services/mixpanel';
import { AxiosError } from 'axios';
import { errorProps, ErrorResponse } from '../../utils/errors';
import NewJobReview from './NewJobReview';
import NewJobSkills from './NewJobSkills';
import { CreateJobPosting, GetJobPosting, UpdateJobPosting } from '../../services/job-postings';
import MobilePreview from './MobilePreview';
import { CheckIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import NewJobInterview from './NewJobInterview';
import LinkToCalendersModal from './job_matches/LinkToCalendersModal';

interface IJobContext {
  job: Partial<Job>;
  setJob: React.Dispatch<React.SetStateAction<Partial<Job>>>;
  saveJob: () => Promise<void>;
  toNextPage: () => void;
  setCurrentPageIdx: React.Dispatch<React.SetStateAction<number>>;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const JobContext = createContext<IJobContext>(null!);
JobContext.displayName = 'JobContext';

export const stages = [
  { name: 'general', title: 'General Information', element: <NewJobGeneral /> },
  { name: 'details', title: 'Education Preferences', element: <NewJobDetails /> },
  { name: 'skills', title: 'Skill Requirements', element: <NewJobSkills /> },
  { name: 'compensation', title: 'Compensation & Benefits', element: <NewJobBenefits /> },
  { name: 'interview', title: 'Interview Settings', element: <NewJobInterview /> },
  { name: 'review', title: 'Review & Confirm', element: <NewJobReview /> },
] as const;

const CreateJob = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [job, setJob] = useState<Partial<Job>>(location.state?.initJob || {});
  const [showSetupCalender, setShowCalenderSetup] = useState<boolean>(false);

  const [currentPageIdx, setCurrentPageIdx] = useState<number>(location.state?.index || 0);

  const { axios } = useAxios();

  const getJobPage = () => {
    return !job.title || !job.company || !job.location_type || !job.description
      ? -1
      : !job.employment_type
      ? 0
      : !job.skills || job.skills.length == 0
      ? 1
      : !job.compensation_period || !job.min_compensation
      ? 2
      : !job.interview_location || !job.interview_duration
      ? 3
      : 4;
  };

  const [lastFilledPage, setLastFilledPage] = useState(job.status == 'active' ? stages.length - 1 : getJobPage());

  const saveJob = async () => {
    if (job.id) {
      // update existing job
      UpdateJobPosting(axios, job)
        .then((res) => {
          //! commenting out this line vvv will allow the information user selects to appear. Maybe revisisting this later.
          setJob(res.data);
          Mixpanel.track(MixpanelEventType.CREATE_JOB_SAVE_UPDATE_SUCCESS);
          return Promise.resolve();
        })
        .catch((err: AxiosError<ErrorResponse>) => {
          console.warn('error updating job post', err);
          Mixpanel.track(MixpanelEventType.CREATE_JOB_SAVE_UPDATE_FAILED, errorProps(err));
          return Promise.reject();
        });
    } else {
      // create new job
      CreateJobPosting(axios, job)
        .then((res) => {
          setJob(res.data);
          Mixpanel.track(MixpanelEventType.CREATE_JOB_SAVE_CREATE_SUCCESS);
          return Promise.resolve();
        })
        .catch((err: AxiosError<ErrorResponse>) => {
          console.warn('error creating job post', err);
          Mixpanel.track(MixpanelEventType.CREATE_JOB_SAVE_CREATE_FAILED, errorProps(err));
          return Promise.reject();
        });
    }
  };

  const toPreviousPage = () => {
    setCurrentPageIdx((prev) => (prev <= 0 ? 0 : prev - 1));
  };

  const toNextPage = () => {
    setCurrentPageIdx((prev) => (prev === stages.length - 1 ? prev : prev + 1));
    setLastFilledPage(lastFilledPage < currentPageIdx ? currentPageIdx : lastFilledPage);
  };

  const setToPage = (idx: number) => {
    if (job.status == 'active' || idx <= lastFilledPage + 1) {
      saveJob();
      setCurrentPageIdx(idx);
    }
  };

  const jobValue = { job, setJob, saveJob, toNextPage, setCurrentPageIdx };

  return (
    <JobContext.Provider value={jobValue}>
      <div className="flex flex-grow flex-col">
        {showSetupCalender ? (
          <LinkToCalendersModal isOpen={showSetupCalender} setIsOpen={setShowCalenderSetup} />
        ) : null}
        <div className="mb-5 text-xl font-semibold">
          <Link className="hover:border-b-2 hover:border-black" to="/jobs">
            Jobs
          </Link>
          <ChevronRightIcon className="inline h-8 fill-primary" />
          {job.id ? 'Review your job post' : 'Create a new job post'}
        </div>

        <div className="flex grow flex-col rounded-lg border border-subtitle/25 bg-white">
          <header className="flex justify-between border-b border-subtitle/25 p-6">
            <h1 className="text-2xl font-bold">{job.id ? 'Review your job post' : 'Create a new job post'}</h1>
            <MobilePreview job={job} />
          </header>

          <div className="grid grow grid-cols-[auto_1fr] gap-5 p-6 pb-0">
            <SideNav currentIdx={currentPageIdx} lastIdx={lastFilledPage} onclick={setToPage} />

            <section className="rounded-lg border border-subtitle/25">{stages[currentPageIdx].element}</section>
          </div>

          <div className="mt-auto flex w-full flex-col gap-x-40 gap-y-4 p-6 lg:flex-row">
            <button
              onClick={currentPageIdx === 0 ? () => navigate('/jobs') : toPreviousPage}
              type="button"
              className="btn border-1 flex-grow border-[#858585] text-[#858585]"
            >
              Back
            </button>
            <button
              type="submit"
              onClick={() => {
                if (currentPageIdx === 5) {
                  setShowCalenderSetup(true);
                }
              }}
              form={stages[currentPageIdx].name}
              className="btn btn-primary flex-grow"
            >
              Save & Continue
            </button>
          </div>
        </div>
      </div>
    </JobContext.Provider>
  );
};

const SideNav = ({
  currentIdx,
  lastIdx,
  onclick,
}: {
  currentIdx: number;
  lastIdx: number;
  onclick: (idx: number) => void;
}) => {
  return (
    <aside className="flex flex-col gap-3 self-start">
      {stages.map(({ name, title }, idx) => (
        <button
          key={name}
          form={stages[currentIdx].name}
          type={idx > currentIdx && idx <= lastIdx + 1 ? 'submit' : 'button'}
          className={clsx(
            'flex justify-between gap-4 rounded-lg border px-2 py-2 lg:px-6',
            idx <= lastIdx + 1 ? 'cursor-pointer hover:bg-gray-200' : 'cursor-default',
            idx === currentIdx ? 'border-primary bg-lightblue text-primary' : 'border-subtitle/25'
          )}
          onClick={(e) => {
            if (idx <= currentIdx || e.currentTarget.form?.reportValidity()) {
              onclick(idx);
            }
          }}
        >
          <span className="hidden self-center whitespace-nowrap text-sm lg:inline">{title}</span>
          <div
            className={clsx(
              'flex h-6 w-6 items-center justify-center rounded-full border text-sm',
              idx <= lastIdx + 1 ? 'border-primary text-primary' : 'border-subtitle/25 text-subtitle/25',
              idx === currentIdx && 'bg-primary text-white'
            )}
          >
            {idx <= lastIdx ? <CheckIcon className="h-4" /> : idx + 1}
          </div>
        </button>
      ))}
    </aside>
  );
};

export default CreateJob;
