import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { ListBulletIcon, Squares2X2Icon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import JobCards from './JobCards';
import JobsTable from './JobsTable';
import { Job } from '../../models/job';
import { useAxios } from '../../contexts/axios';
import Loader from '.././Loader';
import { useNavigate } from 'react-router-dom';
import { GetJobPostings } from '../../services/job-postings';
import { useImmer } from 'use-immer';
import { DeleteJob } from '../../utils/job';

function Jobs() {
  const [jobs, setJobs] = useImmer<Job[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tableView, setTableView] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);

  const { axios } = useAxios();
  const navigate = useNavigate();

  useEffect(() => {
    GetJobPostings(axios, pageCount, 25).then(
      (res) => {
        const count = Number(res.headers['x-total']);
        setTotal(count);
        setJobs(res.data);
        setPageCount(Math.round(count / 25));
        setLoading(false);
      },
      (err) => {
        setError(err);
        setLoading(false);
      }
    );
  }, [pageCount]);

  const removeJob = (id: string) => {
    setJobs((draft) => {
      draft.splice(
        draft.findIndex((job) => job.id === id),
        1
      );
    });
  };

  return loading ? (
    <Loader className="absolute left-1/2 top-1/2 flex" />
  ) : (
    <div className="flex flex-grow flex-col">
      <h1 className="text-2xl font-semibold text-header">Jobs Postings</h1>
      <div className="my-2 flex items-center gap-2">
        <div className="flex-grow">
          {' '}
          {/* results */}
          <div className="font-medium">Total {total} Results</div>
        </div>
        <div>
          {' '}
          {/* search */}
          <form className="w-full md:ml-0" action="#" method="GET">
            <label htmlFor="search-field" className="sr-only">
              Search
            </label>
            <div className="relative focus-within:text-gray-600">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
              </div>
              <input
                id="search-field"
                className="block h-8 w-full rounded-md border-gray-300 py-2 pl-8 pr-3 text-sm leading-5 focus:outline-none focus:ring-0"
                placeholder="Search Jobs..."
                type="search"
                name="search"
              />
            </div>
          </form>
        </div>
        <div>
          {' '}
          {/* view buttons */}
          <span className="isolate inline-flex rounded-md shadow-sm">
            <button
              type="button"
              className={clsx(
                'relative inline-flex items-center rounded-l-md border border-gray-300 px-2 py-2 text-sm font-medium focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary',
                tableView ? 'bg-white text-gray-500 hover:bg-gray-50' : 'bg-primary text-white'
              )}
              onClick={() => setTableView(false)}
              data-testid="card-toggle"
            >
              <span className="sr-only">Card View</span>
              <Squares2X2Icon className="h-4 w-4" aria-hidden="true" />
            </button>
            <button
              type="button"
              className={clsx(
                'relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 px-2 py-2 text-sm font-medium focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary',
                tableView ? 'bg-primary text-white' : 'bg-white text-gray-500 hover:bg-gray-50'
              )}
              onClick={() => setTableView(true)}
              data-testid="table-toggle"
            >
              <span className="sr-only">Table View</span>
              <ListBulletIcon className="h-4 w-4" aria-hidden="true" />
            </button>
          </span>
        </div>
      </div>

      {jobs.length === 0 ? (
        <div className="mt-3 flex flex-grow flex-col items-center justify-center gap-5 rounded-lg bg-white">
          <h1 className="text-4xl font-medium">You don&apos;t have any job postings.</h1>
          <p className="text-subtitle">Create a new job post</p>
          <button
            className="rounded-3xl bg-primary px-14 py-8 font-semibold text-white"
            onClick={() => navigate('create')}
          >
            Post a new job
          </button>
        </div>
      ) : tableView ? (
        <JobsTable />
      ) : (
        <JobCards jobs={jobs} deleteJobCallback={removeJob} />
      )}
    </div>
  );
}

export default Jobs;
