import React, { useContext, useState, useEffect, Fragment } from 'react';
import { JobContext, stages } from './CreateJob';
import { JobTraitChoice } from '../../models/job-trait-choice';
import { Menu, Transition, Listbox } from '@headlessui/react';
import { PencilIcon, TrashIcon, PlusIcon, CheckIcon } from '@heroicons/react/24/outline';
import { EllipsisHorizontalIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { JobTrait } from '../../models/job-trait';
import axios from 'axios';
import { Toast } from '../../utils/toast';

type EllipsisMenuProps = {
  skill: JobTraitChoice;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentJobTraitChoice: React.Dispatch<React.SetStateAction<JobTraitChoice | undefined>>;
};
function EllipsisMenu({ skill, setCurrentJobTraitChoice, setIsEditing }: EllipsisMenuProps) {
  const { job, setJob } = useContext(JobContext);

  // If the user opens the elipsis menu they are presented with delete or edit buttons
  const handleSkillEdit = (skill: JobTraitChoice) => {
    setCurrentJobTraitChoice(skill);
    setIsEditing(true);
  };

  // This handles deleting a skill
  const handleSkillRemoval = (skillId: string) => {
    axios
      .delete(`/recruiters/job_postings/${job.id}/skills/${skillId}`)
      .then(() => {
        const updatedSkills = job.skills?.filter((skill) => skill.id !== skillId);
        setJob((oldJob) => ({ ...oldJob, skills: updatedSkills }));
      })
      .catch((err) => console.error(err));
  };

  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button className="">
          <span className="sr-only">Open skill menu</span>
          <EllipsisHorizontalIcon className="h-5 w-5" />
        </Menu.Button>
      </div>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="w-30 absolute -left-[50px] z-10 mt-2 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="relative">
            <button
              type="button"
              onClick={() => handleSkillEdit(skill)}
              className="flex items-center gap-2 px-4 py-2 text-sm text-gray-700"
            >
              <PencilIcon className="h-4 w-4" />
              Edit
            </button>
          </div>
          <div className="relative">
            <button
              type="button"
              onClick={() => {
                handleSkillRemoval(skill.id);
              }}
              className="flex items-center gap-2 px-4 py-2 text-sm text-red-700"
            >
              <TrashIcon className="h-4 w-4" />
              Delete
            </button>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

type FilterComboBoxProps = {
  jobTraits: JobTrait[];
  currentJobTraitState: [JobTrait, React.Dispatch<React.SetStateAction<JobTrait>>];
  skillCategory: JobTrait | undefined;
  setSkillCategory: React.Dispatch<React.SetStateAction<JobTrait | undefined>>;
};
function FilterComboBox(props: FilterComboBoxProps) {
  const { jobTraits, currentJobTraitState, skillCategory, setSkillCategory } = props;
  const [selected, setSelected] = useState(jobTraits[0]);
  useEffect(() => {
    setSkillCategory(selected);
  }, [selected]);

  return (
    <div>
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block truncate">{selected.name}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {jobTraits.map((traits, index) => (
                <Listbox.Option
                  key={index}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                    }`
                  }
                  value={traits}
                >
                  {({ selected }) => (
                    <>
                      <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                        {traits.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}

type SkillColProps = {
  priority: 1 | 2;
  jobTraits: JobTrait[];
  isEditingState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  activeListState: [1 | 2, React.Dispatch<React.SetStateAction<1 | 2>>];
  currentJobTraitState: [JobTrait, React.Dispatch<React.SetStateAction<JobTrait>>];
  currentJobTraitChoiceState: [
    JobTraitChoice | undefined,
    React.Dispatch<React.SetStateAction<JobTraitChoice | undefined>>,
  ];
};
function SkillCol(props: SkillColProps) {
  const { priority, activeListState, currentJobTraitChoiceState, currentJobTraitState, isEditingState, jobTraits } =
    props;
  const [isEditing, setIsEditing] = isEditingState;
  const [activeList, setActiveList] = activeListState;
  const [currentJobTrait, setCurrentJobTrait] = currentJobTraitState;
  const [currentJobTraitChoice, setCurrentJobTraitChoice] = currentJobTraitChoiceState;
  const [skillCategory, setSkillCategory] = useState<JobTrait>();
  // * Used to get every Colmun's name and color without switches
  // * Cols[idx] finds current Colmun's name and color

  const Cols = [
    {
      name: 'Nice to Have',
      color: '#0082E0',
    },
    {
      name: 'Must Have',
      color: '#C832B0',
    },
  ];
  const idx = priority - 1;
  const { job, setJob } = useContext(JobContext);
  const skills = job.skills?.filter((skill) => skill.priority === priority);

  const [showInput, setShowInput] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  // * Current Radio Input for moving skills from one Column to another
  const [priorityChoice, setPriorityChoice] = useState<1 | 2>(priority);
  // * YearsOfExperience == Years of Experience
  const [YearsOfExperience, setYearsOfExperience] = useState(1);

  // * Used to reset Column on Column Switch

  useEffect(() => {
    if (activeList === priority) return;
    setShowInput(false);
    setShowDetails(false);
  }, [activeList]);

  // * Handles popping up the right Colmun Editing Menu
  useEffect(() => {
    if (!currentJobTraitChoice) return;

    if (currentJobTraitChoice.priority !== priority) return;

    setShowInput(true);
    setShowDetails(true);
    setPriorityChoice(priority);
    setYearsOfExperience(currentJobTraitChoice.experience);
  }, [currentJobTraitChoice]);

  const OpenPill = (item: JobTraitChoice) => {
    if (job.skills?.find((skill) => skill.name === item.name && skill.priority === activeList))
      return Toast.error(`Already Added ${item.name}`);

    setCurrentJobTraitChoice(item);
    setShowDetails(true);
    setShowInput(true);
    setYearsOfExperience(1);
  };

  //  * Handles Adding or Editing a Skill into the Job Context Object
  function handleDetailsSubmit() {
    if (!currentJobTraitChoice) return;

    // nice > must
    const sameSkill = job.skills?.find(
      (skill) => skill.name === currentJobTraitChoice.name && skill.priority !== priority
    );

    if (sameSkill) {
      if (priority === 1 && !(YearsOfExperience > sameSkill.experience)) {
        return Toast.error('Nice to Have must have more experience than Must Have');
      }

      if (priority === 2 && !(sameSkill.experience > YearsOfExperience)) {
        return Toast.error('Nice to Have must have more experience than Must Have');
      }
    }

    // Editing
    if (isEditing) {
      axios
        .put(`/recruiters/job_postings/${job.id}/skills/${currentJobTraitChoice.id}`, {
          skill: { priority: priorityChoice, experience: YearsOfExperience },
        })
        .then(({ data }) => {
          setJob((job) => {
            const newSkills = job.skills || [];
            const idx = newSkills.findIndex((skill) => skill.name === currentJobTraitChoice.name);

            newSkills.splice(idx, 1, data);

            return { ...job, skills: newSkills };
          });
        })
        .catch((err) => console.error(err));
    } else {
      // Adding a New Skill
      axios
        .post(`/recruiters/job_postings/${job.id}/skills`, {
          skill: { job_trait_choice_id: currentJobTraitChoice.id, priority: priority, experience: YearsOfExperience },
        })
        .then(({ data }) => {
          setJob((job) => {
            const newSkills = job.skills || [];
            newSkills.push(data);
            return { ...job, skills: newSkills };
          });
        });
    }
    setShowDetails(false);
    setShowInput(false);
    setIsEditing(false);
  }

  return (
    <div
      style={{ borderColor: Cols[idx].color }}
      className="flex min-h-[33.4vh] grow basis-full flex-col overflow-auto rounded-md border-2"
      onClick={() => {
        // if any part of the div is clicked set as the active col
        setActiveList(priority);
      }}
    >
      <h2
        style={{
          backgroundColor: activeList === priority ? Cols[idx].color : '#FFFFFF',
          color: activeList === priority ? '#FFFFFF' : Cols[idx].color,
        }}
        className="w-full p-3"
      >
        {showDetails ? (
          <>
            <span className="">{isEditing ? 'Editing' : 'Adding'}:</span>
            <span className="ml-2 rounded-md bg-white bg-opacity-10 px-3 py-1">{currentJobTraitChoice?.name}</span>
          </>
        ) : (
          Cols[idx].name
        )}
      </h2>

      <div className="mb-8 grow px-5 pt-2">
        {showInput && !showDetails ? (
          <div className="flex h-full flex-col gap-3">
            {/* Filter ComboBox */}
            <FilterComboBox
              jobTraits={jobTraits}
              currentJobTraitState={currentJobTraitState}
              skillCategory={skillCategory}
              setSkillCategory={setSkillCategory}
            />

            {/* Current Job Trait Choices */}
            <div className="flex flex-wrap gap-3">
              {skillCategory?.choices?.map((item: JobTraitChoice) => (
                <button
                  key={item.id}
                  type="button"
                  onClick={() => {
                    OpenPill(item);
                  }}
                  className="flex items-center gap-1 rounded-full border border-subtitle/50 px-4 py-1 text-subtitle"
                >
                  {item.name}
                  <PlusIcon className="h-4 w-4" />
                </button>
              ))}
            </div>

            <button
              type="button"
              className="mt-auto w-full rounded-lg border border-subtitle/20 p-3 font-medium text-subtitle"
              onClick={() => {
                setShowInput(false);
                setShowInput(false);
                setShowDetails(false);
              }}
            >
              Cancel
            </button>
          </div>
        ) : !showInput ? (
          <ul className="space-y-3">
            {skills?.map((skill) => (
              <li key={skill.id} className="flex items-center gap-3">
                <div
                  style={{ backgroundColor: Cols[idx].color }}
                  className="grid h-10 w-10 shrink-0 place-items-center rounded-full text-white"
                >
                  {skill.experience}+
                </div>

                <label
                  style={{ borderColor: Cols[idx].color, color: Cols[idx].color }}
                  className="rounded-full border px-6 py-2"
                >
                  {skill.name}
                </label>

                <div className="ml-auto mr-5">
                  <EllipsisMenu
                    skill={skill}
                    setIsEditing={setIsEditing}
                    setCurrentJobTraitChoice={setCurrentJobTraitChoice}
                  />
                </div>
              </li>
            ))}
          </ul>
        ) : null}

        {showDetails ? (
          <div className="flex h-full flex-col">
            {isEditing ? (
              <div className="text-center">
                Want to move it to another category?
                <div className="flex justify-evenly">
                  <label className="flex flex-col items-center justify-center">
                    <input
                      type="radio"
                      name="ToHave"
                      checked={priorityChoice === 1}
                      onChange={() => setPriorityChoice(1)}
                      id="NiceToHave"
                      className="text-primary"
                    />
                    Nice to Have
                  </label>
                  <label className="flex flex-col items-center justify-center">
                    <input
                      type="radio"
                      name="ToHave"
                      checked={priorityChoice === 2}
                      onChange={() => setPriorityChoice(2)}
                      id="NiceToHave"
                      className="text-primary"
                    />
                    Must Have
                  </label>
                </div>
              </div>
            ) : null}

            <label className="">
              Minimum years of experience
              <select
                name=""
                id=""
                className=""
                defaultValue=""
                value={YearsOfExperience}
                onChange={(e) => setYearsOfExperience(parseInt(e.target.value))}
              >
                <option value="" disabled>
                  Please Select Years Of Experience
                </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10+</option>
              </select>
            </label>

            <div className="mt-auto flex gap-10 ">
              <button
                type="button"
                className="w-full  rounded-lg border border-subtitle/20 p-3 font-medium text-subtitle"
                onClick={() => {
                  setShowInput(false);
                  setShowInput(false);
                  setShowDetails(false);
                }}
              >
                Cancel
              </button>

              <button
                onClick={handleDetailsSubmit}
                type="button"
                className="w-full whitespace-nowrap rounded-lg bg-primary p-3 font-medium text-white"
              >
                Continue
              </button>
            </div>
          </div>
        ) : null}

        {showInput ? (
          <></>
        ) : (
          <>
            <div className="relative left-1/4 mb-8 w-1/2">
              <div className="flex items-center justify-center">
                <button
                  className="mt-10"
                  style={{ color: Cols[idx].color }}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowInput(true);
                  }}
                >
                  + Add skill
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default function NewJobSkills() {
  const { job, setJob, toNextPage } = useContext(JobContext);

  const [isEditing, setIsEditing] = useState(false);
  const [activeList, setActiveList] = useState<1 | 2>(1);

  const [currentJobTrait, setCurrentJobTrait] = useState<JobTrait>({} as JobTrait);
  const [currentJobTraitChoice, setCurrentJobTraitChoice] = useState<JobTraitChoice>();

  // * Gets the JobTraits for the Industry Selected Earlier
  const [jobTraits, setJobTraits] = useState<JobTrait[]>([]);
  useEffect(() => {
    axios
      .get<JobTrait[]>(`/recruiters/job_fields/${job.job_field_id}/job_traits`)
      .then(({ data }) => {
        setJobTraits(data);
        if (data.length > 0) {
          setCurrentJobTrait(data[0]);
        }
      })
      .catch((err) => console.error(err));
  }, []);

  // * Gets the current Job's JobTraitChoices
  useEffect(() => {
    axios
      .get<JobTraitChoice[]>(`/recruiters/job_postings/${job.id}/skills`)
      .then(({ data }) => {
        setJob((job) => ({ ...job, skills: data }));
      })
      .catch((err) => console.error(err));
  }, []);

  // When the user is done with this page they proceed to the review. However (May, 25th) Nina is working on getting another new page for the create
  // job flow so this navigation will have to be updated.
  // saveJob Function was removed as it reset the skills
  const handleContinueSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    toNextPage();
  };

  // This Object is used so the SkillCol Props look smaller
  const SkillColProps = {
    jobTraits: jobTraits,
    isEditingState: [isEditing, setIsEditing],
    activeListState: [activeList, setActiveList],
    currentJobTraitChoiceState: [currentJobTraitChoice, setCurrentJobTraitChoice],
    currentJobTraitState: [currentJobTrait, setCurrentJobTrait],
  } satisfies Omit<SkillColProps, 'priority'>;

  return (
    <form onSubmit={(e) => handleContinueSubmit(e)} id={stages[2].name} className="flex h-full flex-col gap-5  p-5">
      <h2 className="font-bold">For this {job.title} to be successful, what experience should they have?</h2>

      <div className="flex grow flex-col gap-7 lg:flex-row">
        <SkillCol priority={1} {...SkillColProps} />
        <SkillCol priority={2} {...SkillColProps} />
      </div>
    </form>
  );
}
