import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Job } from '../../models/job';
import { useAxios } from '../../contexts/axios';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

interface IJobSurveyModal {
  job: Partial<Job>;
  onClose: () => void;
}
type JobSurveyType = {
  job_posting_close_survey: {
    filled: boolean | null;
    unfilled_reasons: string[];
  };
};

export default function JobSurveyModal({ job, onClose }: IJobSurveyModal) {
  const [roleFilled, setRollFilled] = useState<boolean>(false);
  const [reason, setReason] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const [surveyData, setSurveyData] = useState<JobSurveyType>({
    job_posting_close_survey: {
      filled: false,
      unfilled_reasons: [],
    },
  });
  const navigate = useNavigate();
  const { axios } = useAxios();

  useEffect(() => {
    setSurveyData({
      ...surveyData,
      job_posting_close_survey: {
        ...surveyData.job_posting_close_survey,
        filled: roleFilled,
        unfilled_reasons: reason,
      },
    });
  }, [roleFilled, reason]);

  const survey = (reason: string) => {
    if (surveyData.job_posting_close_survey.unfilled_reasons.includes(reason)) {
      setReason((prevSurvey) => prevSurvey.filter((item) => item !== reason));
    } else if (surveyData.job_posting_close_survey.unfilled_reasons.includes(reason) === false) {
      setReason((prevSurvey) => [...prevSurvey, reason]);
    }
  };

  const SubmitSurvey = () => {
    setLoading(true);
    axios
      .put(`/recruiters/job_postings/${job?.id}/close_survey`, surveyData)
      .then(() => {
        onClose();
        navigate('/jobs');
      })
      .catch((error) => {
        console.error('Error making PUT request:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    SubmitSurvey();
  }

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
        <div className="relative mx-auto my-6 w-auto max-w-3xl">
          {/*content*/}
          <div className="relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
            {/*header*/}
            <div className="border-blueGray-200 flex items-start justify-between rounded-t border-b border-solid p-5">
              <h3 className="text-2xl font-semibold">Close Job Post</h3>
              <button
                className="float-right ml-auto border-0 bg-transparent p-1 text-3xl font-semibold leading-none text-black opacity-5 outline-none focus:outline-none"
                onClick={onClose}
              >
                <span className="block h-6 w-6 bg-transparent text-2xl text-black opacity-5 outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}

            <div className="">
              <div className="flex flex-col items-center gap-2 bg-green-500/20 py-2">
                <h3 className="text-3xl text-green-500">Success!</h3>
                <CheckCircleIcon className="h-16 w-16 text-green-500" />
                <p className="text-xl text-subtitle/80">Job has been successfully closed.</p>
              </div>
              <form id="jobSurveyForm" onSubmit={handleSubmit} className="flex flex-col gap-2 px-6 py-4 text-xl">
                <h2>Tell us more</h2>
                <h2>Has the role been filled?</h2>
                <div className="flex gap-5">
                  <label className="flex items-center gap-2 text-lg text-subtitle">
                    <input
                      type="radio"
                      name="isFilled"
                      value="Yes"
                      checked={roleFilled}
                      required
                      onChange={() => {
                        setRollFilled(true);
                      }}
                    />
                    Yes
                  </label>
                  <label className="flex items-center gap-2 text-lg text-subtitle">
                    <input
                      type="radio"
                      name="isFilled"
                      value="No"
                      required
                      checked={!roleFilled}
                      onChange={() => {
                        setRollFilled(false);
                      }}
                    />
                    No
                  </label>
                </div>
                <h2>If no, is your reason for closing the role any of the following?</h2>
                <label className="flex gap-2 text-subtitle">
                  <input
                    type="checkbox"
                    name="reasonForClosing[]"
                    value="on_hold"
                    onChange={(e) => {
                      survey(e.target.value);
                    }}
                  />
                  Position is on hold
                </label>
                <label className="flex gap-2 text-subtitle">
                  <input
                    type="checkbox"
                    name="reasonForClosing[]"
                    value="position_eliminated"
                    onChange={(e) => {
                      survey(e.target.value);
                    }}
                  />
                  Position is on eliminated
                </label>
                <label className="flex gap-2 text-subtitle">
                  <input
                    type="checkbox"
                    name="reasonForClosing[]"
                    value="bad_matches"
                    onChange={(e) => {
                      survey(e.target.value);
                    }}
                  />
                  Bad Matches
                </label>
                <label className="flex gap-2 text-subtitle">
                  <input
                    type="checkbox"
                    name="reasonForClosing[]"
                    value="not_enough_applicants"
                    onChange={(e) => {
                      survey(e.target.value);
                    }}
                  />
                  Not enough candidates in the pipeline
                </label>
                <label className="flex gap-2 text-subtitle">
                  <input
                    type="checkbox"
                    name="reasonForClosing[]"
                    value="other"
                    onChange={(e) => {
                      survey(e.target.value);
                    }}
                  />
                  Other
                </label>
              </form>
            </div>

            {/*footer*/}
            <div className="border-blueGray-200 flex items-center justify-end rounded-b border-t border-solid p-6">
              <button
                className="background-transparent mb-1 mr-6 border  px-6 py-2 text-sm font-bold text-black outline-none transition-all duration-150 ease-linear focus:outline-none"
                type="button"
                onClick={onClose}
              >
                Skip
              </button>
              <button
                className="mb-1 mr-1 rounded bg-primary px-6 py-3 text-sm font-bold text-white shadow outline-none transition-all duration-150 ease-linear hover:shadow-lg focus:outline-none"
                type="submit"
                form="jobSurveyForm"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
    </>
  );
}
