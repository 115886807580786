import React from 'react';
import { Job } from '../../models/job';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import Badge from '../shared/badge';
import { daysUntil } from '../../utils/date';

interface IConfirmDeleteModal {
  job: Partial<Job>;
  onClose: () => void;
  onConfirm: () => void;
}

export default function ConfirmDeleteModal({ job, onClose, onConfirm }: IConfirmDeleteModal) {
  const expirationCountdown = job.expires_at ? daysUntil(job.expires_at) : 0;
  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
        <div className="relative mx-auto my-6 w-auto max-w-3xl">
          {/*content*/}
          <div className="relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
            {/*header*/}
            <div className="border-blueGray-200 flex items-start justify-between rounded-t border-b border-solid p-5">
              <h3 className="text-2xl font-semibold">Close Job Post</h3>
              <button
                className="float-right ml-auto border-0 bg-transparent p-1 text-3xl font-semibold leading-none text-black opacity-5 outline-none focus:outline-none"
                onClick={onClose}
              >
                <span className="block h-6 w-6 bg-transparent text-2xl text-black opacity-5 outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}

            <div>
              <div className="relative flex-auto p-6">
                <p className="text-blueGray-500 my-4 text-lg leading-relaxed">
                  Are you sure you want to close this job posting?
                </p>
                <div className="h-11/12 w-full rounded-md border">
                  <div className="">
                    <div className="m-4 flex flex-row content-center">
                      <div className="w-1/5">
                        <QuestionMarkCircleIcon className="h-10 w-10 rounded-md border bg-black text-primary" />
                      </div>
                      <div className="flex w-4/5 flex-col content-center">
                        <h1>{job?.title}</h1>
                        <h1 className="text-subtitle">{job?.company}</h1>
                      </div>
                    </div>

                    <div>
                      <h1 className="ml-4 text-subtitle">Candidates:</h1>
                      <div className="mx-4 mt-2 flex flex-row justify-start rounded-md bg-lightblue">
                        <div className="ml-4 w-1/2 ">
                          <div className="m-2 border-l-2 border-primary pl-4">
                            <p className="text-sm text-subtitle">TOTAL</p>
                            <p className="font-bold">{job?.stats?.matches}</p>
                          </div>
                        </div>
                        <div className="w-1/2">
                          <div className="m-2 border-l-2 border-primary pl-4">
                            <p className="text-sm text-subtitle">NEW</p>
                            <p className="font-bold">{job?.stats?.new_matches}</p>
                          </div>
                        </div>
                      </div>
                      <div className="m-4 flex flex-row content-center justify-start">
                        <div className="mr-4">
                          <Badge color={expirationCountdown >= 15 ? 'green' : 'red'}>{expirationCountdown} days</Badge>
                        </div>
                        <div className="mr-4">
                          <Badge color="blue">3 interviews</Badge>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*footer*/}
              <div className="border-blueGray-200 flex items-center justify-end rounded-b border-t border-solid p-6">
                <button
                  className="background-transparent mb-1 mr-6 border  px-6 py-2 text-sm font-bold text-black outline-none transition-all duration-150 ease-linear focus:outline-none"
                  type="button"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  className="mb-1 mr-1 rounded bg-primary px-6 py-3 text-sm font-bold text-white shadow outline-none transition-all duration-150 ease-linear hover:shadow-lg focus:outline-none"
                  type="button"
                  onClick={onConfirm}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
    </>
  );
}
